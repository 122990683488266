@media screen and (max-width: 769px) {
  .nav {
    // padding-bottom: 2rem
    &, &_body {
      flex-direction: column; }
    &_body {
      position: fixed;
      width: 90%;
      max-width: 16.5rem;
      top: 0;
      bottom: 0;
      background-color: $bg;
      transition: transform 0.3s var(--easing);
      &_right {
        transform: translateX(100vw);
        right: 0; }
      &_left {
        transform: translateX(-100vw);
        left: 0; } }

    &_close {
      width: 3rem;
      position: absolute;
      right: -4rem;
      top: 0;
      bottom: 0;
      height: 100%;
      cursor: pointer;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1.25rem;
        fill: var(--light);
        height: 1.25rem;
        display: none;
        &:first-child {
          display: initial; }
        &.isopen {
          display: none;
          + svg {
            display: initial; } } } }

    &_brand {
      position: relative;
      z-index: 999;
      width: calc(100% - 3rem);
      padding-left: 0; }

    &_parent {
      display: grid; }
    &_sub {
      position: relative;
      top: initial;
      padding-top: 0; } }

  .jsopen {
    &::after {
      content: "";
      position: fixed;
      z-index: 2;
      background-color: rgba(0,0,0,0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .nav {
      &_body {
        transform: translateX(0);
        padding-left: 1.5rem;
        padding-right: 1.5rem; }
      &_parent {
        &:first-child {
         margin-top: 4.4rem; } }

      .follow {
        justify-content: flex-start;
        flex: initial;
        margin-top: 0.75rem; } } } }
